import React, { useEffect, useState, useCallback, connect, mapStateToProps } from 'react';
import { HomePage } from './pages/home';
import { SuccessPage } from './pages/successPage';
import { ShopPage } from './pages/shopPage';
import { CartPage } from './pages/cartPage';
import { AboutPage } from './pages/about';
import { FAQ } from './pages/FAQ';
import { ContactPage } from './pages/contact';
import { Delivery } from './pages/delivery';
import { Return } from './pages/return';
import { Privacy } from './pages/privacy';
import Error from './pages/error';
import firebase from './components/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, withRouter } from "react-router-dom";
import { TOTAL_PRODUCTS, SHOP_NAMES } from './store/actions/actionNames';
import ScrollTop from './components/ScrollTop';
import v4 from 'uuid';

export default function App() {
  const store = firebase.firestore();
  const dispatch = useDispatch();
  const shopNames = useSelector(state => state.shopNames);
  const [pro, setPro] = useState([]);

  useEffect(useCallback(() => {
    store.collection('AllShops').get().then((snapshot) => {
      let allShops = []
      snapshot.docs.forEach(doc => {
        allShops.push(doc.data().shopName)
      })
      dispatch({type: SHOP_NAMES, payLoad: allShops})
    })
  }, [shopNames]), []);
  
  useEffect(() => {
    shopNames.map(function (names) {
      const dataRef = store.collection("AllShops").doc(names).collection('products');
      dataRef.get()
        .then(Response => {
          Response.forEach(document => {
            const itemObject = document.data();
            const productItems = {
              id: v4(),
              name: itemObject.productName,
              img: itemObject.images,
              price: Math.round(itemObject.productPrice - (itemObject.productPrice / 100) * itemObject.sale),
              description: itemObject.description,
              gender: itemObject.gender,
              brand: names,
              category: itemObject.category,
              size: itemObject.productSize,
              color: itemObject.color,
              ar: itemObject.ar,
              sale: itemObject.sale,
              type: itemObject.type,
              date: itemObject.date
            };
            setPro([...pro, pro.push(productItems)]);
          })
        })
    })
      dispatch({ type: TOTAL_PRODUCTS, payLoad: pro });
  },[shopNames])

  return (
      <ScrollTop>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/success">
          <SuccessPage />
        </Route>
        <Route path="/shop">
          <ShopPage />
        </Route>
        <Route path="/cart">
          <CartPage />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/FAQ">
          <FAQ />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="/delivery">
          <Delivery />
        </Route>
        <Route path="/return">
          <Return />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
      </ScrollTop>
  );
};