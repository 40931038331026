import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/actionNames';

//--- Components import --
import { HeaderComponent } from '../../components/header';
import { Footer } from '../../components/footer';
import { SideBar } from '../../components/sideBar';



export const Return = () => {
    const dispatch = useDispatch();
    //... Function that turn the preview modal on ..
    useEffect(useCallback(() => {
        dispatch({ type: actions.PREVIEW_CHANGE });
    }, []), [])

    //--- Products from redux store --
    const products = useSelector(state => state.ProductsService);

    //--- The state of preview modal where it is turn on or off --
    const PreviewState = useSelector(state => state.ProductPreview);

    //--- Main body style when side bar is hidden --
    const style = {
        left: '0px'
    }
    const [newProduct, setNewProduct] = useState(products);

    useEffect(() => {
        setNewProduct(products)
    }, [products])
    return (
        <div>
            <SideBar />
            <div id="wrapper" style={style}>
                <HeaderComponent />
                <h1 className="FooterParts">Վերադարձ</h1>
                <h4 className="FooterParts">Եթե գոհ չեք ձեր գնումից, մենք 14 օրվա ընթացքում կընդունենք չօգտագործված արտադրանքի վերադարձը:
                    Վերադարձված ապրանքը ստանալուց հետո FlyShop- ը կտրամադրի ամբողջ գումարը:
                    Զեղչված իրերը իրավասու են վերադարձի համար:
                    FlyShop- ը չի տրամադրի փոխհատուցումներ այլ սուբյեկտների միջոցով ձեռք բերված ապրանքների համար, ինչպիսիք են դիստրիբյուտորները կամ մանրածախ գործընկերները:
                    Վերադարձված իրերը պետք է մեզ առաքվեն չօգտագործված, նախնական փաթեթավորման մեջ և ստացված վիճակում:
                    Մենք չենք կարող պատասխանատվություն կրել վնասված կամ կորած իրերի համար:
                    Կան իրեր, որոնք իրավասու չեն վերադարձի
                    Մենք ի վիճակի չենք փոխհատուցում տրամադրել առանց առարկայի (ներ) ի և փաստացի ստացման վերադարձի ապացույցների:
                    Մենք նպատակ ունենք ընդունել բոլոր վերադարձները:
                    Անհավատալի դեպքում, երբ որևէ առարկա վերադառնա մեզ ոչ պիտանի վիճակում, գուցե մենք ստիպված լինենք այն ուղարկել ձեզ:
                    Վերադարձի ընթացքում ստուգվելու են բոլոր ապրանքները:</h4>
                <Footer />
            </div>
        </div>
    )
}
