import React from 'react';
import { Link } from "react-router-dom";

export const CategoryComponent = () => {
    return (
        <div>
             <section className="top_catagory_area d-md-flex clearfix">
            <div className="single_catagory_area d-flex align-items-center bg-img2">
                <div className="catagory-content">
                    <h6>Աքսեսուարներ</h6>
                    <h2>Զեղչ 30%</h2>
                    <Link to="/shop" className="btn fly-btn">գնել հիմա</Link>
                </div>
            </div>
            <div className="single_catagory_area d-flex align-items-center bg-img1">
                <div className="catagory-content">
                    <h6>նոր հավաքածու</h6>
                    <h2>նորաոճ պայուսակներ</h2>
                    <Link to="/shop" className="btn fly-btn">գնել հիմա</Link>
                </div>
            </div>
        </section>
        </div>
    )
}
