import React from 'react';
import firebase, {auth} from '../firebase/firebase';

export const SideBar = () => {
    const [coderesult, setCoderesult] = React.useState();
    
    const phoneAuth  = (event) => {
        event.preventDefault();
        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        recaptchaVerifier.render()
        var number = document.getElementById('number').value;
        firebase.auth().signInWithPhoneNumber(number, recaptchaVerifier).then(function(confirmationResult) {
            confirmationResult = confirmationResult;
            setCoderesult(confirmationResult);
        }).catch(function(err) {
            console.log(err.message);
        })
    };

    const codeverify = (event) => {
        event.preventDefault();
        var code = document.getElementById('verificationCode').value;
        coderesult.confirm(code).then(function (result) {
            const {user} = result;
            window.location.reload();
        }).catch(function(err) {
            alert(err.message);
        })
    }
 
    //... Side bar close function ..
    const sideBarClose = () => {
        const target = document.getElementById('wrapper').style;
        if (target.left == '300px') {
            target.left = '0px';
            document.getElementById('wrapper').className = "";
        }
    }
    
    return (
        <div className="catagories-side-menu">
            <div onClick={() => sideBarClose()} id="sideMenuClose">
                <i className="ti-close"></i>
            </div>
            <div className="nav-side-menu">
                <div className="menu-list">
                    <h6>Գրանցվել</h6>
                    <ul id="menu-content" className="menu-content collapse out">
                        <form className="sign_up_form" onSubmit={phoneAuth}>
                            <input type="text" id="number" placeholder="+374" />
                            <div id="recaptcha-container"></div>
                            <button type="submit">Ուղարկել</button>
                        </form>
                        <h1 className="verification">Հաստատման կոդ</h1>
                        <form className="sign_up_form">
                            <input type="text" id="verificationCode" placeholder="Մուտքագրեք կոդը" />
                            <button type="submit" onClick={codeverify}>Հաստատել</button>
                        </form>
                    </ul>
                </div>
            </div>
        </div>
    )
}
