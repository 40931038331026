import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import qs from 'qs';
import firebase from 'firebase';
import {db, increment, auth} from '../firebase/firebase';

export const CartCheckOut = () => {
    const cartProducts = useSelector(state => state.CartReducer);
    const totalPrice = useSelector(state => state.cartTotalPrice);
    const [cartPrice, setPrice] = useState(0);
    const [total, setTotal] = useState(totalPrice);
    const [shippingWord, setShippingWord] = useState('Free');
    const [data, setData] = useState({});

   useEffect(() => {
    let price = 0;
    cartProducts.map(function (product) {
        price = price + product.price;
    })
    setPrice(price);
   }, [cartProducts]);

    useEffect(() => {
        setTotal(totalPrice);
    }, [totalPrice]);

    const changeShipping = (shipping) => {
        if(shipping === 'Free') {
            setTotal(totalPrice + 0);
            setShippingWord('Free');
        }
        else {
        setTotal(totalPrice + shipping);
        setShippingWord(`$${shipping}`);
        }
    }


    const InitPayment = async () => {
        const address = document.getElementById('address').value;
        let products = [];
        localStorage.setItem('products', JSON.stringify(cartProducts));


        if(address.length === 0){
            alert("Xndrum enq lracnel hascen");
        } else {
            localStorage.setItem('address', address);

            let prod = localStorage.getItem('products');
            JSON.parse(prod).map(item => {
                products.push({
                    size: item.size,
                    category: item.category,
                    image: item.img,
                    productId: item.productId,
                    productName: item.name,
                    productPrice: item.price,
                    sale: item.sale,
                    gender: item.gender,
                    type: item.type,
                    brand: item.brand,
                    shipped: false,
                    color: item.color
                })
            });

            localStorage.setItem('products', JSON.stringify(products));

            // Document reference
            const storyRef = db.collection('WebOrderID').doc('ORDERID');

            // Update read count
            storyRef.update({ OrderID: increment });

            axios({
                url: "https://firestore.googleapis.com/v1/projects/flyshop-4e6ea/databases/(default)/documents/WebOrderID/ORDERID",
                method: "GET"
            }).then((response) => {
                axios({
                    url: `https://flyshop.am/PaymentTest.php`,
                    method: 'POST',
                    data: qs.stringify({
                        ClientID: '1bae2fef-43b9-48b7-9297-565eee0aba33',
                        Description: 'Gnum',
                        OrderID: response.data.fields.OrderID.integerValue,
                        Amount: 10
                    }),
                    headers: { "content-type": "application/x-www-form-urlencoded" }
                })
                    .then((response) => {
                        const {PaymentID, ResponseCode} = response.data;
                        if(ResponseCode === 1){
                            window.location.href = `https://servicestest.ameriabank.am/VPOS/Payments/Pay?id=${PaymentID}`;
                        }
                    })
                    .catch((err) => console.log(err.response));

                    return () => {};
            }).catch((err) => console.log(err.response));

            return () => {};
        }
    }

    const sideBarToggle = () => {
        const target = document.getElementById('wrapper').style;
        if (target.left === '0px') {
            target.left = '300px';
            document.getElementById('wrapper').className = "fly-side-menu-open";
        } else {
            target.left = '0px';
            document.getElementById('wrapper').className = "";
        }
    }

    var user = firebase.auth().currentUser;
    return (
        <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="cart-total-area mt-70">
                            <div className="cart-page-heading">
                                <h5>Ընդհանուր առևտուր</h5>
                                <p>Վերջնական տեղեկատվություն</p>
                            </div>

                            <ul className="cart-total-chart">
                                <li><span>Վճարման ենթակա</span> <span>{totalPrice} AMD</span></li>
                                <li><span>Առաքում</span> <span>Անվճար</span></li>
                                <li><span><strong>Ընդհանուր</strong></span> <span><strong>{total} AMD</strong></span></li>
                                <li><span><strong>Առաքման Հասցե</strong></span> <span><input type="text" id="address" placeholder="Առաքման Հասցե" required></input></span></li>
                            </ul>

                            {user ? 
                            <button className="btn fly-checkout-btn" onClick={InitPayment}>Անցնել վճարման</button>
                            :
                            <button className="btn fly-checkout-btn" onClick={sideBarToggle}>Մուտք գործել</button>}
                        </div>
                    </div>
                </div>
    )
}
