import React from 'react';
import { Link } from 'react-router-dom';

export const MainFooterField = () => {
    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="single_footer_area">
                        <div className="footer-logo justify-content-center d-flex">
                            <img src="img/core-img/logo.png" className="footer-logo-size mx-auto" alt="" />
                        </div>
                        <div className="copywrite_text d-flex align-items-center">
                            <p>
                            Հեղինակային իրավունք  &copy;<script>document.write(new Date().getFullYear());</script> Բոլոր իրավունքները պահպանված են | Պատրաստված է <i className="fa fa-heart-o" aria-hidden="true"></i> FlyShop- ի կողմից</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                    <div className="single_footer_area">
                        <ul className="footer_widget_menu">
                            <li><Link to="/about" style={{color: 'rgba(0, 0, 0, 0.85)'}}>Մեր Մասին</Link></li>
                            <li><Link to="/FAQ" style={{color: 'rgba(0, 0, 0, 0.85)'}}>FAQ</Link></li>
                            <li><Link to="/contact" style={{color: 'rgba(0, 0, 0, 0.85)'}}>Կապ</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                    <div className="single_footer_area">
                        <ul className="footer_widget_menu">
                            <li><Link to="/delivery" style={{color: 'rgba(0, 0, 0, 0.85)'}}>Առաքում</Link></li>
                            <li><Link to="/return" style={{color: 'rgba(0, 0, 0, 0.85)'}}>Վերադարձ</Link></li>
                            <li><Link to="/privacy" style={{color: 'rgba(0, 0, 0, 0.85)'}}>քաղաքականություն</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <div className="single_footer_area">
                        <div className="footer_heading mb-30">
                            <h6>Բաժանորդագրվեք մեր տեղեկագրին</h6>
                        </div>
                        <div className="subscribtion_form">
                            <form action="#" method="post">
                                <input type="email" name="mail" className="mail" placeholder="Ձեր էլ-փոստը " />
                                <button type="submit" className="submit">Բաժանորդագրվել</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line"></div>
        </div>
    )
}
