import React from 'react';
import firebase from 'firebase';
import axios from 'axios';
import qs from 'qs';
import {Link} from 'react-router-dom';
import { HeaderComponent } from '../../components/header';
import { Footer } from '../../components/footer';
import {db, increment} from '../../components/firebase/firebase';
import { Result, Button } from 'antd';

export const SuccessPage = () => {
    var orderReg = /[a-z \| 0-9 \| -]*/g;
    var patt = /paymentID=[a-z \| 0-9 \| -]*/g;
    var successRes = /%d5%af%d5%a1%d5%bf%d5%a1%d6%80%d5%be%d5%a1%d5%ae/g;
    const url = window.location.href;
    const success = url.match(successRes);
    const result = url.match(patt);
    const paymentID = result[0].match(orderReg);
    const PaymentID = paymentID[4];
    const data = {
        PaymentID
    };

    let cartProd = JSON.parse(localStorage.getItem('products'));

    // https://flyshop.am/success?orderID=2342580&resposneCode=00&paymentID=cced26a7-f60c-4085-8727-326e26561bc7&opaque=&description=%d4%b3%d5%b8%d6%80%d5%ae%d6%84%d6%80%d6%84%d5%a8+%d5%af%d5%a1%d5%bf%d5%a1%d6%80%d5%be%d5%a1%d5%ae+%d5%a7

    axios({
        url: `https://flyshop.am/GetPaymentDetails.php`,
        method: 'POST',
        data: qs.stringify(data),
        headers: { "content-type": "application/x-www-form-urlencoded" }
    })
        .then((response) => {
            db.collection("Orders").doc(firebase.auth().currentUser.phoneNumber).set({
                products: cartProd,
                paymentDetails: {
                    PaymentID,
                    Amount: response.data.Amount,
                    CardHolder: response.data.ClientName,
                    Address: localStorage.getItem('address'),
                },
            })
            .then(function() {
                console.log("Document successfully written!");
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });

            setTimeout(function(){
                window.location.assign('/');
            }, 3000);
        })
        .catch((err) => {
            console.log(err.response);
        });

    return (
        <div>
            <HeaderComponent />
            {/* <section className="w-100 bg-light d-flex justify-content-center align-items-center" style={{height: "500px"}}>
                <div className="text-center">
                    <h1>Գործարքը կատարէ</h1>
                </div>
            </section> */}
            {success && success[0] === '%d5%af%d5%a1%d5%bf%d5%a1%d6%80%d5%be%d5%a1%d5%ae' ? 
            <Result
                status="success"
                title="Գործարքը կատարված է"
                subTitle={`Մեր աշխատակիցը հնարավորինս արագ կապ կհաստատի ձեզ հետ։`}
                extra={[
                    <Link to="/">
                        <Button key="buy">Գլխավոր</Button>,
                    </Link>
                ]}
            /> 
            : 
            <Result
                status="error"
                title="Գործարքը մերժվեց։"
                subTitle="Խնդրում ենք ստուգել տվյալները և փորձել նորից։"
                extra={[
                    <Link to="/">
                        <Button key="buy">Գլխավոր</Button>,
                    </Link>
                ]}
            ></Result>}
            <Footer />
        </div>
    );
}

