export const SHOP_NAMES = 'SHOP_NAMES';
export const TOTAL_PRODUCTS = 'TOTAL_PRODUCTS';
export const PREVIEW_CHANGE = 'PREVIEW_CHANGE';
export const PREVIEW_SET = 'PREVIEW_SET';


export const GET_FILTER = 'GET_FILTER';

export const CART_ADD = 'CART_ADD';
export const CART_PRODUCT_DELETE = 'CART_PRODUCT_DELETE';
export const CART_PRODUCTS_CLEAR = 'CART_PRODUCTS_CLEAR';

export const INITIAL_PRICE =  'INITIAL_PRICE';
export const PRICE_CHANGE = 'PRICE_CHANGE';