export const filterBytypes = [
    {
        name: 'Սեռ',
        toggleTarget: 'women',
        subProperty: [
            {
                text: 'Տղամարդու',
                target: 'gender',
                filter: 'Արական',
                select: false,
            }, {
                text: 'Կանացի ',
                target: 'gender',
                filter: 'Իգական',
                select: false,
            }
        ]
    }, {
        name: 'Բրենդ',
        toggleTarget: 'man',
        subProperty: [
            {
                text: 'Pierre Cardin',
                target: 'brand',
                filter: 'Pierre Cardin',
                select: false,
            },
            {
                text: 'GIORDANO',
                target: 'brand',
                filter: 'GIORDANO',
                select: false,
            },
             {
                text: 'Mexx',
                target: 'brand',
                filter: 'MEXX',
                select: false,
            },
             {
                text: 'U.S. POLO ASSN.',
                target: 'brand',
                filter: 'U.S. POLO ASSN.',
                select: false,
            }
            ,
             {
                text: 'Zeal Socks',
                target: 'brand',
                filter: 'Zeal Socks',
                select: false,
            }
            ,
             {
                text: 'Zaletti',
                target: 'brand',
                filter: 'Zaletti',
                select: false,
            }
            ,
             {
                text: 'Top Secret',
                target: 'brand',
                filter: 'Top Secret',
                select: false,
            }
            ,
             {
                text: 'Broadway',
                target: 'brand',
                filter: 'Broadway',
                select: false,
            }
        ]
    }, {
        name: 'Տեսակ',
        toggleTarget: 'kids',
        subProperty: [
            {
                text: 'Հագուստ',
                target: 'category',
                filter: 'Հագուստ',
                select: false,
            }, {
                text: 'Կոշիկ',
                target: 'category',
                filter: 'Կոշիկ',
                select: false,
            }, {
                text: 'Աքսեսուարներ',
                target: 'category',
                filter: 'Աքսեսուարներ',
                select: false,
            }
        ]
    }
];

export const typeSubClothingFilters = [
    {
        target: 'type',
        text: 'Կոստյումներ',
        type: 'Կոստյումներ',
        select: false,
    }, {
        target: 'type',
        text: 'Շապիկներ',
        type: 'Շապիկներ',
        select: false,
    }, {
        target: 'type',
        text: 'Շորտեր',
        type: 'Շորտեր',
        select: false,
    }, {
        target: 'type',
        text: 'Շապիկներ : Պոլոներ',
        type: 'Շապիկներ : Պոլոներ',
        select: false,
    }, {
        target: 'type',
        text: 'Ջինս',
        type: 'Ջինս',
        select: false,
    }, {
        target: 'type',
        text: 'Հուդիներ և Սվիտերներ',
        type: 'Հուդիներ և Սվիտերներ',
        select: false,
    }, {
        target: 'type',
        text: 'Բաճկոններ',
        type: 'Բաճկոններ',
        select: false,
    }, {
        target: 'type',
        text: 'Լողազգեստներ',
        type: 'Լողազգեստներ',
        select: false,
    }, {
        target: 'type',
        text: 'Սպորտային Հագուստ',
        type: 'Սպորտային Հագուստ',
        select: false,
    }
    , {
        target: 'type',
        text: 'Գուլպա',
        type: 'Գուլպա',
        select: false,
    }
];

export const typeSubShoesFilters = [
    {
        target: 'type',
        text: 'Ամառային դասական',
        type: 'Ամառային դասական',
        select: false,
    }, {
        target: 'type',
        text: 'Սանդալ',
        type: 'Սանդալ',
        select: false,
    }, {
        target: 'type',
        text: 'Ամառային սպորտային',
        type: 'Ամառային սպորտային',
        select: false,
    }, {
        target: 'type',
        text: 'Դասական կոշիկ​',
        type: 'Դասական կոշիկ​',
        select: false,
    }, {
        target: 'type',
        text: 'Չարոխ',
        type: 'Չարոխ',
        select: false,
    }, {
        target: 'type',
        text: 'Սպորտային',
        type: 'Սպորտային',
        select: false,
    }, {
        target: 'type',
        text: 'Կիսաճտքավոր դասական',
        type: 'Կիսաճտքավոր դասական',
        select: false,
    }, {
        target: 'type',
        text: 'Կիսաճտքավոր սպորտային',
        type: 'Կիսաճտքավոր սպորտային',
        select: false,
    }, {
        target: 'type',
        text: 'Ուգգի',
        type: 'Ուգգի',
        select: false,
    }
];

export const typeSubAccessoryFilters = [
    {
        target: 'type',
        text: 'Ժամացույցներ',
        type: 'Ժամացույցներ',
        select: false,
    }, {
        target: 'type',
        text: 'Արևային ակնոցներ',
        type: 'Արևային ակնոցներ',
        select: false,
    }, {
        target: 'type',
        text: 'Պայուսակներ',
        type: 'Պայուսակներ',
        select: false,
    }, {
        target: 'type',
        text: 'Գլխարկներ',
        type: 'Գլխարկներ',
        select: false,
    }, {
        target: 'type',
        text: 'Գոտիներ',
        type: 'Գոտիներ',
        select: false,
    }, {
        target: 'type',
        text: 'Զարդեր',
        type: 'Զարդեր',
        select: false,
    }
];

export const manShoes = ["36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46"];
export const womanShoes = ["35", "36", "37", "38", "39", "40", "41"];
export const manTrousers = ["36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46"];
export const womanTrousers = ["31", "34", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54"];
export const extraTypesSize = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];



