import React from "react";
import {Link} from 'react-router-dom'
import { Footer } from '../../components/footer';
import { HeaderComponent } from '../../components/header';

export default function Error() {
  return (
    <>
      <HeaderComponent />
        <section className="w-100 bg-light d-flex justify-content-center align-items-center" style={{height: "500px"}}>
          <div className="text-center">
            <span style={{fontSize: '150px'}}>404</span>
            <h1>Page not found</h1>
            <Link to='/' className="btn fly-checkout-btn">Back to home</Link>
          </div>
        </section>
          <Footer />
    </>
  )
}