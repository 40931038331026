import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/actionNames';

//--- Components import --
import { HeaderComponent } from '../../components/header';
import { Footer } from '../../components/footer';
import { SideBar } from '../../components/sideBar';

export const ContactPage = () => {
    const dispatch = useDispatch();
    //... Function that turn the preview modal on ..
    useEffect(useCallback(() => {
        dispatch({ type: actions.PREVIEW_CHANGE });
    }, []), [])

    //--- Products from redux store --
    const products = useSelector(state => state.ProductsService);

    //--- The state of preview modal where it is turn on or off --
    const PreviewState = useSelector(state => state.ProductPreview);

    //--- Main body style when side bar is hidden --
    const style = {
        left: '0px'
    }
    const [newProduct, setNewProduct] = useState(products);

    useEffect(() => {
        setNewProduct(products)
    }, [products])
    return (
        <div>
            <SideBar />
            <div id="wrapper" style={style}>
                <HeaderComponent />
                <h1 className="FooterParts">Կապ</h1>
                <h4 className="FooterParts"><a href="tel:+37433988988">Հեռախոսահամար</a></h4>
                <h4 className="FooterParts"><a href="https://t.me/flyshopcompany">Telegram </a></h4>
                <h4 className="FooterParts"><a href="mailto:flyshopcompany@gmail.com">Mail</a></h4>
                <h4 className="FooterParts"><a href="#">Հասցե։ Մարշալ Բաղրամյան 85</a></h4>
                <Footer />
            </div>
        </div>
    )
}
