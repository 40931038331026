import React from 'react';

export const Fixedads = () => {
    return (
        <section className="top-discount-area d-md-flex align-items-center">
            <div className="single-discount-area">
                <h5>Անվճար առաքում և վերադարձ</h5>
                <h6>Վերադարձ 14 օրվա ընթացքում</h6>
            </div>
            <div className="single-discount-area">
                <h5>FlyShop</h5>
                <h6>Բոլոր խանութները մեկ հարթակում</h6>
            </div>
            <div className="single-discount-area">
                <h5>Ապրանքի դիտում AR-ի միջոցով</h5>
                <h6>Ներբեռնեք ծրագիրը</h6>
            </div>
        </section>
    )
}
