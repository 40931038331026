import React from 'react';
import { FaWindowClose } from "react-icons/fa";
import { Link } from 'react-router-dom';

export const BagState = ({ bagProducts, deleteCartProduct }) => {
    return (
        <li>
            <Link to="/cart" className="image"><img src={bagProducts.img} className="cart-thumb" alt="" /></Link>
            <div className="cart-item-desc">
                <div className="bag_block">
                    <h6>{bagProducts.category}</h6>
                    <p>1x - <span className="price">{bagProducts.price} AMD</span></p>
                </div>
                <FaWindowClose onClick={() => deleteCartProduct(bagProducts.id)} className="bag_close_button"/>
            </div>
            <span className="dropdown-product-remove"><i className="icon-cross"></i></span>
        </li>
    )
}
