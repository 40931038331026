import React from 'react';

import { MainFooterField } from './footerComponents/mainField';
import { FooterBottomIcons } from './footerComponents/bottomIcons';

export const Footer = () => {
    return (
        <footer className="footer_area">
            <div className="container">
                <MainFooterField />
                <FooterBottomIcons />
            </div>
        </footer>
    )
}
