import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {auth} from '../../firebase/firebase';
import { BagState } from './bagMenu';
import { CART_PRODUCT_DELETE } from '../../../store/actions/actionNames';

export const HeadSection = () => {
    const dropDown = useRef();
    const [priceCount, setPrice] = useState(0);
    const bagProducts = useSelector(state => state.CartReducer);
    const [current, setUser] = useState(null);

    auth.onAuthStateChanged(user => {
        if(user){
            setUser(user);
        }
    })

    const dispatch = useDispatch();
    //--- Drop down menu style--
    const DropStyle = {
        display: 'none'
    }

    //... Menu drop down function ..
    const openDrop = () => {
        const target = dropDown.current.style;
        if (target.display === 'none') {
            target.display = 'block';
        } else {
            target.display = 'none';
        }
    }

    //... Count total price in cart..
    useEffect(() => {
        let prices = 0;
        for (var i = 0; i < bagProducts.length; i++) {
            prices += bagProducts[i].price
        }
        setPrice(prices);
    }, [bagProducts]);

    //...Side bar toggle function..
    const sideBarToggle = () => {
        const target = document.getElementById('wrapper').style;
        if (target.left === '0px') {
            target.left = '300px';
            document.getElementById('wrapper').className = "fly-side-menu-open";
        } else {
            target.left = '0px';
            document.getElementById('wrapper').className = "";
        }
    }

    const deleteCartProduct = (deleteTarget) => {
        dispatch({type: CART_PRODUCT_DELETE, payLoad: deleteTarget});
    };

    return (
        <header className="header_area">
            <div className="top_header_area">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-end">

                        <div className="col-12 col-lg-7">
                            <div className="top_single_area d-flex align-items-center">
                                <div className="top_logo">
                                    <a href="/"><img src="img/core-img/logo.png" alt="FlyShop-Logo" /></a>
                                </div>
                                <div className="header-cart-menu d-flex align-items-center ml-auto">
                                    <div className="cart">
                                        <p onClick={() => openDrop()} className="drop_down">
                                            {bagProducts.length >= 0 && <span className="cart_quantity">{bagProducts.length}</span>}
                                            <i className="ti-bag"></i> Զամբյուղ {priceCount} AMD</p>
                                        <ul className="cart-list" ref={dropDown} style={DropStyle}>
                                            {bagProducts.map((item, i) =>
                                                <BagState deleteCartProduct={deleteCartProduct} bagProducts={bagProducts[i]} />
                                            )}
                                            <li className="total">
                                                <span className="pull-right">Ընդանուր {priceCount} AMD</span>
                                                <Link className="btn btn-sm btn-cart" to={"/cart"}>Զամբյուղ</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="header-right-side-menu ml-15">
                                        
                                        {current != null ? 
                                            <p id="sideMenuBtn" style={{backgroundColor: "#FF084E"}}><i className="ti-user" aria-hidden="true"></i></p> 
                                                : 
                                            <p id="sideMenuBtn" onClick={() => sideBarToggle()}><i className="ti-user" aria-hidden="true"></i></p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main_header_area">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 d-flex justify-content-between">
                            <nav className="navbar navbar-expand-lg navbar-light w-100 justify-content-between">
                                <div className="navbar-brand mx-auto d-flex">
                                    <div className="header-social-area">
                                        <a href="https://apps.apple.com/us/app/id1520739251" style={{color: 'rgba(0, 0, 0, 0.85)'}}><span className="fly-level">Ներբեռնել</span> <i className="fa fa-apple" aria-hidden="true"></i></a>
                                        <a href="https://cdn1.bbcode0.com/uploads/2020/7/23/270feff1f06661cecfa1e39a97451cb8-full.png" style={{color: 'rgba(0, 0, 0, 0.85)'}}><i className="fa fa-android" aria-hidden="true"></i></a>
                                        <a href="https://www.instagram.com/flyshop.am/" style={{color: 'rgba(0, 0, 0, 0.85)'}}><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                        <a href="https://www.facebook.com/flyshop.am" style={{color: 'rgba(0, 0, 0, 0.85)'}}><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                    </div>
                                    <div className="help-line mx-auto d-block d-lg-none">
                                        <a href="tel:+374-33-988-988"><i className="ti-headphone-alt"></i> +374-33-988-988</a>
                                    </div>
                                </div>
                                <button className="navbar-toggler" style={{borderRadius: '50px'}} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSuppurtedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="ti-menu"></i>
                                </button>

                                <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                                    <ul className="navbar-nav animated mx-auto" id="nav">
                                        <li className="nav-item py-2"><Link className="px-4 dropdown-item" to="/">Գլխավոր</Link></li>
                                        <li className="nav-item py-2"><Link className="px-4 dropdown-item" to="/shop">Շոփինգ</Link></li>
                                        <li className="nav-item py-2"><Link className="px-4 dropdown-item" to="/cart">Զամբյուղ</Link></li>
                                        <li className="nav-item py-2"><a href="https://t.me/flyshopcompany" className="px-4 dropdown-item">Օգնություն</a></li>
                                    </ul>
                                </div>
                                <div className="help-line mx-auto d-none d-lg-block">
                                    <a href="tel:+374-33-988-988"><i className="ti-headphone-alt"></i> +374-33-988-988</a>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            
        </header>
    )
}