import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/actionNames';

//--- Components import --
import { HeaderComponent } from '../../components/header';
import { Footer } from '../../components/footer';
import { SideBar } from '../../components/sideBar';



export const Privacy = () => {
    const dispatch = useDispatch();
    //... Function that turn the preview modal on ..
    useEffect(useCallback(() => {
        dispatch({ type: actions.PREVIEW_CHANGE });
    }, []), [])

    //--- Products from redux store --
    const products = useSelector(state => state.ProductsService);

    //--- The state of preview modal where it is turn on or off --
    const PreviewState = useSelector(state => state.ProductPreview);

    //--- Main body style when side bar is hidden --
    const style = {
        left: '0px'
    }
    const [newProduct, setNewProduct] = useState(products);

    useEffect(() => {
        setNewProduct(products)
    }, [products])
    return (
        <div>
            <SideBar />
            <div id="wrapper" style={style}>
                <HeaderComponent />
                <h1 className="FooterParts">Քաղաքականություն</h1>
                <h4 className="FooterParts">FlyShop- ում, որը հասանելի է www.flyshop.am կայքից, մեր հիմնական գերակայություններից մեկը մեր այցելուների գաղտնիությունն է: Գաղտնիության քաղաքականության այս փաստաթուղթը պարունակում է տեղեկատվության տեսակներ, որոնք հավաքվում և գրանցվում են FlyShop- ի կողմից, և թե ինչպես ենք դրանք օգտագործում:

Եթե ​​ունեք լրացուցիչ հարցեր կամ մեր Գաղտնիության քաղաքականության վերաբերյալ լրացուցիչ տեղեկություններ եք պահանջում, մի հապաղեք կապվեք մեզ հետ:

Այս Գաղտնիության քաղաքականությունը տարածվում է միայն մեր առցանց գործունեության վրա և ուժի մեջ է մեր կայքէջի այցելուների համար ՝ կապված այն տեղեկությունների հետ, որոնք նրանք կիսել և (կամ) հավաքում են FlyShop- ում: Այս քաղաքականությունը կիրառելի չէ անցանց կամ այս կայքից բացի այլ ալիքներով հավաքված ցանկացած տեղեկատվության համար: Մեր Գաղտնիության քաղաքականությունը ստեղծվել է Գաղտնիության քաղաքականության գեներատորի և Generate Գաղտնիության քաղաքականության գեներատորի միջոցով:

Համաձայնություն
Օգտագործելով մեր կայքը ՝ դուք սույնով համաձայնում եք մեր Գաղտնիության քաղաքականությանը և համաձայնում եք դրա պայմաններին:

Տեղեկատվություն, որը մենք հավաքում ենք
Այն անձնական տեղեկությունները, որոնք ձեզնից խնդրում են տրամադրել, և պատճառները, թե ինչու են ձեզ տրամադրում, դրանք ձեզ պարզ կդառնան այն կետում, երբ մենք խնդրում ենք ձեզ տրամադրել ձեր անձնական տվյալները:

Եթե ​​ուղղակիորեն կապվեք մեզ հետ, մենք կարող ենք լրացուցիչ տեղեկություններ ստանալ ձեր մասին, ինչպիսիք են ձեր անունը, էլ. Փոստի հասցեն, հեռախոսահամարը, հաղորդագրության բովանդակությունը և / կամ կցորդները, որոնք կարող եք ուղարկել մեզ, և ցանկացած այլ տեղեկատվություն, որը դուք կընտրեք տրամադրել:

Հաշվի գրանցման ժամանակ մենք կարող ենք հայցել ձեր կոնտակտային տվյալները, ներառյալ այնպիսի իրեր, ինչպիսիք են անունը, ընկերության անվանումը, հասցեն, էլ. Փոստի հասցեն և հեռախոսահամարը:

Ինչպես ենք մենք օգտագործում ձեր տեղեկատվությունը
Մենք հավաքում ենք տեղեկատվությունը տարբեր ձևերով, այդ թվում `

Ապահովել, գործել և պահպանել մեր վեբ կայքը
Բարելավել, անհատականացնել և ընդլայնել մեր ոստայնը
Հասկացեք և վերլուծեք, թե ինչպես եք օգտագործում մեր վեբ կայքը
Մշակել նոր ապրանքներ, ծառայություններ, առանձնահատկություններ և ֆունկցիոնալություն
Շփվեք ձեզ հետ, ուղղակիորեն կամ մեր գործընկերներից մեկի միջոցով, ներառյալ հաճախորդների սպասարկման համար, ձեզ նորություններ և այլ տեղեկատվություն տրամադրելու, որոնք վերաբերում են ցանցին, ինչպես նաև շուկայավարման և գովազդային նպատակներով:
Ուղարկել ձեզ էլ
Գտեք և կանխեք խարդախությունները
Մուտք գործեք
FlyShop- ը հետևում է մատյան ֆայլերի օգտագործման ստանդարտ ընթացակարգին: Այս ֆայլերը գրանցում են այցելուներին, երբ նրանք այցելում են կայքեր: Բոլոր հոստինգ ընկերություններն անում են դա և հոստինգի ծառայությունների վերլուծության մի մասը: Տեղեկատվական ֆայլերի կողմից հավաքված տեղեկատվությունը ներառում է ինտերնետային պրոտոկոլի (IP) հասցեներ, զննարկչի տեսակը, Ինտերնետ Provառայությունների Պրովայդեր (ISP), ամսաթվի և ժամի դրոշմը, հղման / ելքի էջերը և հնարավոր է կտտացնումների քանակը: Դրանք կապված չեն անձամբ ճանաչելի որևէ տեղեկատվության հետ: Տեղեկատվության նպատակը միտումների վերլուծությունը, կայքի կառավարումն է, կայքում օգտվողների շարժի հետևումը և ժողովրդագրական տեղեկատվության հավաքագրումը:

Գովազդային գործընկերների գաղտնիության քաղաքականություն
Դուք կարող եք խորհրդակցել այս ցուցակի հետ ՝ Գաղտնիության քաղաքականությունը գտնելու համար FlyShop- ի գովազդային գործընկերներից յուրաքանչյուրի համար:

Երրորդ կողմի գովազդային սերվերները կամ գովազդային ցանցերը օգտագործում են այնպիսի տեխնոլոգիաներ, ինչպիսիք են թխուկները, JavaScript- ը կամ Web Beacons- ը, որոնք օգտագործվում են իրենց համապատասխան գովազդներում և FlyShop- ում հայտնվող հղումներում, որոնք ուղարկում են անմիջապես օգտվողների դիտարկիչին: Երբ դա տեղի է ունենում, նրանք ավտոմատ կերպով ստանում են ձեր IP հասցեն: Այս տեխնոլոգիաներն օգտագործվում են չափելու իրենց գովազդային արշավների արդյունավետությունը և / կամ գովազդային բովանդակությունն անհատականացնելու համար, որը տեսնում եք ձեր այցելած կայքերում:

Նշենք, որ FlyShop- ը մուտքի իրավունք չունի և չի վերահսկում այդ թխուկները, որոնք օգտագործվում են երրորդ կողմի գովազդատուների կողմից:

Երրորդ կողմերի գաղտնիության քաղաքականություն
FlyShop- ի Գաղտնիության քաղաքականությունը չի տարածվում այլ գովազդատուների կամ կայքերի վրա: Այսպիսով, մենք ձեզ խորհուրդ ենք տալիս խորհրդակցել այս երրորդ կողմի գովազդային սերվերների Գաղտնիության համապատասխան քաղաքականության հետ ՝ ավելի մանրամասն տեղեկատվություն ստանալու համար: Այն կարող է ներառել նրանց պրակտիկան և հրահանգները որոշակի տարբերակներից հրաժարվելու մասին:

Կարող եք ընտրել թխուկները անջատել զննարկչի ձեր անհատական ​​ընտրանքների միջոցով: Հատուկ վեբ զննարկիչների միջոցով քուքիի կառավարման վերաբերյալ ավելի մանրամասն տեղեկություններ իմանալու համար դրանք կարելի է գտնել զննարկիչների համապատասխան կայքերում:

CCPA- ի գաղտնիության իրավունքներ (մի վաճառեք իմ անձնական տվյալները)
Համաձայն CCPA- ի, ի թիվս այլ իրավունքների, Կալիֆորնիայի սպառողները իրավունք ունեն.

Հայցեք, որ բիզնեսի կողմից, որը հավաքում է սպառողի անձնական տվյալները, բացահայտի այն կատեգորիաները և անձնական տվյալների որոշակի մասերը, որոնք ձեռնարկությունը հավաքել է սպառողների վերաբերյալ:

Հայցեք, որ ձեռնարկությունը ջնջի սպառողի վերաբերյալ ցանկացած անձնական տվյալ, որը ձեռնարկությունը հավաքել է:

Հայցեք, որ բիզնեսը, որը վաճառում է սպառողի անձնական տվյալները, այլ ոչ թե վաճառի սպառողի անձնական տվյալները:

Եթե ​​հարցում եք կատարում, մենք մեկ ամիս ժամանակ ունենք պատասխանելու ձեզ: Եթե ​​ցանկանում եք օգտագործել այս իրավունքներից որևէ մեկը, խնդրում ենք կապվել մեզ հետ:

GDPR տվյալների պաշտպանության իրավունքներ
Մենք կցանկանայինք համոզվել, որ դուք լիովին տեղյակ եք ձեր տվյալների պաշտպանության ձեր բոլոր իրավունքներից: Յուրաքանչյուր օգտվող իրավունք ունի հետևյալի.

Մուտքի իրավունք - Դուք իրավունք ունեք հայցել ձեր անձնական տվյալների պատճենները: Մենք կարող ենք ձեզնից մի փոքր վճար գանձել այս ծառայության համար:
Ուղղման իրավունք - Դուք իրավունք ունեք պահանջել, որ մենք ուղղենք ցանկացած տեղեկություն, որը կարծում եք, որ անճիշտ է: Դուք նաև իրավունք ունեք պահանջելու, որ մենք լրացնենք այն տեղեկատվությունը, որը կարծում եք թերի է:

Eնջելու իրավունք - Դուք իրավունք ունեք պահանջել, որ մենք ջնջենք ձեր անձնական տվյալները ՝ որոշակի պայմաններում:

Մշակումը սահմանափակելու իրավունք - Դուք իրավունք ունեք պահանջել, որ մենք սահմանափակենք ձեր անձնական տվյալների մշակումը, որոշակի պայմաններում:

Մշակման դեմ առարկելու իրավունք - Դուք իրավունք ունեք առարկելու մեր անձնական տվյալների մշակմանը, որոշակի պայմաններում:

Տվյալների տեղափոխելիության իրավունք - Դուք իրավունք ունեք պահանջել, որ մենք հավաքած տվյալները փոխանցենք այլ կազմակերպության կամ ուղղակիորեն ձեզ ՝ որոշակի պայմաններով:

Եթե ​​հարցում եք կատարում, մենք մեկ ամիս ժամանակ ունենք պատասխանելու ձեզ: Եթե ​​ցանկանում եք օգտագործել այս իրավունքներից որևէ մեկը, խնդրում ենք կապվել մեզ հետ:

Երեխաների տեղեկատվություն
Մեր առաջնահերթության մեկ այլ մասը երեխաների համար պաշտպանություն ավելացնելն է `ինտերնետից օգտվելիս: Մենք խրախուսում ենք ծնողներին և խնամակալներին դիտարկել, մասնակցել և (կամ) վերահսկել և ղեկավարել նրանց առցանց գործունեությունը:

FlyShop– ը գիտակցաբար չի հավաքում որևէ անձնական նույնականացման տեղեկատվություն մինչև 13 տարեկան երեխաներից: Եթե կարծում եք, որ ձեր երեխան այս կայքում տեղեկատվություն է տրամադրել մեր կայքում, մենք խստորեն խրախուսում ենք ձեզ անմիջապես կապվել մեզ հետ և մենք ամեն ինչ կանենք, որպեսզի անհապաղ հեռացնենք դրանք: այդպիսի տեղեկատվություն մեր գրառումներից:</h4>
                <Footer />
            </div>
        </div>
    )
}
