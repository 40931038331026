import React from 'react';

export const FooterBottomIcons = () => {
    return (
        <div className="footer_bottom_area">
            <div className="row">
                <div className="col-12">
                    <div className="footer_social_area text-center">
                        <a href="https://apps.apple.com/us/app/id1520739251" style={{color: 'rgba(0, 0, 0, 0.85)'}}><i className="fa fa-apple" aria-hidden="true"></i></a>
                        <a href="https://cdn1.bbcode0.com/uploads/2020/7/23/270feff1f06661cecfa1e39a97451cb8-full.png" style={{color: 'rgba(0, 0, 0, 0.85)'}}><i className="fa fa-android" aria-hidden="true"></i></a>
                        <a href="https://www.instagram.com/flyshop.am/" style={{color: 'rgba(0, 0, 0, 0.85)'}}><i className="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href="https://www.facebook.com/flyshop.am" style={{color: 'rgba(0, 0, 0, 0.85)'}}><i className="fa fa-facebook" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
