import React from 'react';
import { Link } from 'react-router-dom';

export const OfferAreaComponent = () => {
    return (
        <div>
            <section className="offer_area height-700 section_padding_100 bg-img">
                <div className="container h-100">
                    <div className="row h-100 align-items-end justify-content-end">
                        <div className="col-12 col-md-8 col-lg-6">
                            <div className="offer-content-area wow fadeInUp" data-wow-delay="1s">
                                <h2>սպիտակ վերնաշապիկ<span className="fly-level">թեժ առաջարկ</span></h2>
                                <p>Իմացիր առաջինը FlyShop-ից</p>
                                <div className="offer-product-price">
                                    <h3><span className="regular-price">84 000 AMD</span> 45 000 AMD</h3>
                                </div>
                                <Link to="/shop" className="btn fly-btn mt-30">գնել հիմա</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}