import React from 'react';

export const ImageCarousel1 = () => {

    return (
            <div className="single_slide1 height-800 bg-img background-overlay">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12">
                            <div className="welcome_slide_text">
                                <h6 data-animation="bounceInDown" data-delay={0} data-duration="500ms">Եղիր նորաձև FlyShop-ի շնորհիվ</h6>
                                <h2 data-animation="fadeInUp" data-delay="500ms" data-duration="500ms">ժամանակակից նորաձևություն</h2>
                                <a href="/shop" className="btn fly-btn" data-animation="fadeInUp" data-delay="1s" data-duration="500ms">Անցնել գնումների</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export const ImageCarousel2 = () => {

    return (
            <div className="single_slide2 height-800 bg-img background-overlay">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12">
                            <div className="welcome_slide_text">
                                <h6 data-animation="bounceInDown" data-delay={0} data-duration="500ms">Նոր հնարավորություններ</h6>
                                <h2 data-animation="fadeInUp" data-delay="500ms" data-duration="500ms">FlyShop հավելվածում</h2>
                                <a href="https://apps.apple.com/us/app/id1520739251" className="btn fly-btn" data-animation="fadeInUp" data-delay="1s" data-duration="500ms">ներբեռնել</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export const ImageCarousel3 = () => {

    return (
            <div className="single_slide3 height-800 bg-img background-overlay">
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12">
                            <div className="welcome_slide_text">
                                <h6 data-animation="bounceInDown" data-delay={0} data-duration="500ms">Սպասեք մեզ</h6>
                                <h2 data-animation="fadeInUp" data-delay="500ms" data-duration="500ms">Play Market-ում</h2>
                                <a href="https://cdn1.bbcode0.com/uploads/2020/7/23/270feff1f06661cecfa1e39a97451cb8-full.png" className="btn fly-btn" data-animation="fadeInUp" data-delay="1s" data-duration="500ms">շուտով</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

