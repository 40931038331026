import React, {useRef, useCallback} from 'react';
import { CART_ADD } from '../../store/actions/actionNames';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-bootstrap';


export const PreviewModal = ({ PreviewState }) => {
    const dispatch = useDispatch();
    const size = useRef();

    //... Add to cart function ..
    const cartAdd = () => {
        if(size.current.value === ''){
            alert('Խնդրում ենք ընտրել չափսը։');
        } else {
            PreviewState = {...PreviewState, size: size.current.value};
            dispatch({ type: CART_ADD, payLoad: PreviewState });
            var view = document.getElementById("quickview")
            view.style.display = "none";
            document.getElementsByClassName("modal-open")[0].style.overflow = 'scroll';
        }
    };

    const qtyMinus = () => {
        var effect = document.getElementById('qty'); 
        var qty = effect.value; 
        if( !isNaN( qty ) && qty && 1 ) 
            effect.value--;
        return false;
    };

    const qtyPlus = () => {
        var effect = document.getElementById('qty'); 
        var qty = effect.value; 
        if( !isNaN( qty )) 
            effect.value++;
        return false;
    }
    return (
        <div className="modal fade" id="quickview" tabIndex="-1" role="dialog" aria-labelledby="quickview" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close btn" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <div className="modal-body">
                        <div className="quickview_body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-5">
                                        <div className="quickview_pro_img">
                                            <Carousel>
                                                {PreviewState.img && PreviewState.img.map((item, i) =>
                                                    <Carousel.Item key={i}>
                                                        <img className="carouse_images" src={item} alt="" />
                                                    </Carousel.Item>
                                                )}
                                            </Carousel>
                                            {/* <p>Բնութագիր:  <span>{PreviewState.description}</span></p> */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-7">
                                        <div className="quickview_pro_des">
                                            <h4 className="title">{PreviewState.brand}</h4>
                                            
                                            <h5 className="price">{PreviewState.price} AMD</h5>
                                            <p>{PreviewState.text}</p>
                                            <div className="modal_ads">
                                                <h5>Անվանում:  <p>{PreviewState.name}</p></h5>
                                                <h5>Բնութագիր:  <span>{PreviewState.description}</span></h5>
                                                <h5>
                                                    Չափս:
                                                    <select className="size_select" ref={size}>
                                                        <option value="">Ընտրել չափսը</option>
                                                        {PreviewState.size && PreviewState.size.map((n, i) =>
                                                            <option>{n}</option>
                                                        )}
                                                    </select>
                                                </h5>

                                            </div>
                                        </div>
                                        <form className="cart" method="post">
                                            <div className="quantity">
                                                <span className="qty-minus" onClick={qtyMinus}><i className="fa fa-minus" aria-hidden="true"></i></span>

                                                <input type="number" className="qty-text" id="qty" step="1" min="1" max="12" name="quantity" value="1" />

                                                <span className="qty-plus" onClick={qtyPlus}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                            </div>
                                            <p onClick={() => cartAdd()} className="cart-submit">Ավելացնել</p>
                                            <div className="modal_pro_wishlist">
                                                <a href="wishlist.html" target="_blank"><i className="ti-heart"></i></a>
                                            </div>
                                            <div className="modal_pro_compare">
                                                <a href="compare.html" target="_blank"><i className="ti-stats-up"></i></a>
                                            </div>
                                        </form>

                                        <div className="share_wf mt-30">
                                            <p>Ավելի պարզ հավելվածում</p>
                                            <div className="_icon">
                                                <a href="https://apps.apple.com/us/app/id1520739251"><i className="fa fa-apple" aria-hidden="true"></i></a>
                                                <a href="https://ibb.co/bLpFy92"><i className="fa fa-android" aria-hidden="true"></i></a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
